import { Component, OnInit, Inject } from '@angular/core';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LpFullCompany, LpFullCompanyAdditionnalUser } from 'app/meta/fullCompany';
import { ConfigService } from 'app/services/config.service';
import { IHubCheckPlusProvider } from 'app/providers/ihubcheckplus.provider';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_VATDOMAIN_ID: string = '1';
const DEFAULT_USERNAME: string = '_ERP';
const DEFAULT_LANGUAGE: string = '001';
const DEFAULT_PASSWORD: string = 'vegagcsi01$';

@Component({
  selector: 'lp-modal-addcompany',
  templateUrl: './lp-modal-addcompany.component.html'
})
export class LpModalAddCompanyComponent extends LpModalComponent implements OnInit {
   
  public dataForm: LpFullCompany = new LpFullCompany();
  protected arrayClassesElements: Map<String, String> = new Map<String, String>();

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private configService: ConfigService,
    private ihubcheckpluProvider: IHubCheckPlusProvider,
    private modalService: ModalService,
    private translateService: TranslateService
  ) {
    super(dialogRef, data);
  }

  public async ngOnInit(): Promise<void> {
    this.intArrayElements();
  }

  public changeProperty(value: String, property: String) {
    switch (property) {
      case "address1":
          if(
            this.dataForm.location.mainAddress.address1 === '' || 
            this.dataForm.location.mainAddress.address1 === null || 
            this.dataForm.location.mainAddress.address1 === undefined
            ) {
              this.dataForm.location.mainAddress.address1 = value;
            }
        break;
      case "zipCode":
        if(
          this.dataForm.location.mainAddress.zipCode === '' ||
          this.dataForm.location.mainAddress.zipCode === null || 
          this.dataForm.location.mainAddress.zipCode === undefined
          ) {
            this.dataForm.location.mainAddress.zipCode = value;
          }
        break;
      case "city":
        if(
          this.dataForm.location.mainAddress.city === '' || 
          this.dataForm.location.mainAddress.city === null || 
          this.dataForm.location.mainAddress.city === undefined
          ) {
            this.dataForm.location.mainAddress.city = value;
          }
        break;
      default:
        break;
    }
  }

  public addUser(): void {
    this.dataForm.additionnalUsers.push(new LpFullCompanyAdditionnalUser());
  }

  public removeUser(i: number): void {
    this.dataForm.additionnalUsers.splice(i, 1);
  }

  public async save(): Promise<void> {
    this.initDefaultValues();
    await this.ihubcheckpluProvider.postCompany(this.dataForm);
    this.modalService.success(
      this.translateService.instant('general.modalService.recordingDone'),
      this.translateService.instant('general.modalService.success')
    );
    this.dialogRef.close();
  }

  private intArrayElements(): void {
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
  }

  private initDefaultValues(): void {
    this.dataForm.company.vatDomain.id = DEFAULT_VATDOMAIN_ID;

    this.dataForm.userSynchroERP.username = `${this.dataForm.company.corporateName}${DEFAULT_USERNAME}`;
    this.dataForm.userSynchroERP.language = DEFAULT_LANGUAGE;
    this.dataForm.userSynchroERP.password = DEFAULT_PASSWORD;

    this.dataForm.manager.mainAddress.address1 = this.dataForm.company.address1;
    this.dataForm.manager.mainAddress.city = this.dataForm.company.city;
    this.dataForm.manager.mainAddress.zipCode = this.dataForm.company.postcode;

    if (
        this.dataForm &&
        this.dataForm.additionnalUsers &&
        this.dataForm.additionnalUsers.length > 0
        ) {
          for (let item of this.dataForm.additionnalUsers) {
              item.thirdParty.mainAddress = this.dataForm.location.mainAddress;
              item.user.username = `${this.dataForm.company.corporateName}_${Array.from(item.thirdParty.firstName.toUpperCase())[0]}${Array.from(item.thirdParty.firstName.toUpperCase())[1]}${Array.from(item.thirdParty.lastName.toUpperCase())[0]}`;
              item.user.password = DEFAULT_PASSWORD;
              item.user.language = DEFAULT_LANGUAGE;
          }      
        }
  }
}
